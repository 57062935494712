<template>
    <div class="ranking" >
      <div class="topNull"></div>
      <!-- <h2>热歌榜</h2> -->
      <div class="listBox">
        <div class="top">
          <div class="timebox">
            <div class="time"></div>
            更新时间:
            {{ this.playlist.trackUpdateTime | formatDate("m 月 d 日") }}
          </div>
          <div class="right">
            <p>
              {{ this.playlist.description }}
            </p>
          </div>
        </div>

        <song-lists
          :tracks="hotList"
          @play="$emit('play', $event)"
        ></song-lists>
        <loading v-if="isLoading" />
      </div>
    </div>
</template>
<script>
import SongLists from "@/components/SongLists";
import Loading from "@/components/Loading";
export default {
  name: "Ranking",
  data() {
    return {
      playlist: [],
      tracks: [],
      hotList: [],
      page: 0, //记录页码
      perpage: 10, //每页显示10条
      isLoading: true,
    };
  },
  components: {
    SongLists,
    Loading,
  },
  methods: {
    loadeMore() {
      this.isLoading = true;
      this.page++; // 1  2
      let start = this.page * this.perpage; // 20   40
      let end = (this.page + 1) * this.perpage; // 40   60
      this.hotList = this.hotList.concat(this.tracks.slice(start, end));
      this.isLoading = false;
    },
    onScroll() {
      // console.log("scrollHeight", document.documentElement.scrollHeight);
      // console.log("scrollTop", document.documentElement.scrollTop);
      // console.log("clientHeight", document.documentElement.clientHeight);
      let h =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      let ch =
        document.documentElement.clientHeight || document.body.clientHeight;
      if (h - top - ch < 40) {
        console.log("1111111111111111");
        this.loadeMore();
      }
    },
  },
  created() {
    //热歌榜
    this.$axios.get(`/playlist/detail?id=3778678`).then((s) => {
      // this.playlist = s.data.playlist;
      // this.tracks = this.playlist.tracks.slice(1, 26);
      // this.tracks = this.playlist.tracks.slice(1, 26);
      // this.song = this.data.slice(0,6);

      this.playlist = s.data.playlist;
      console.log("song=>", this.playlist);
      this.tracks = s.data.playlist.tracks.map((data, index) => {
        //转化数据格式
        data.song = {
          privilege: s.data.privileges[index],
          artists: data.ar, //转化歌手
          album: data.al, //转化专辑
        };
        // console.log(data);
        this.isLoading = false;
        return data;
      });
      console.log(this.tracks);
      // slice(0,20)=>1 slice(20,40)=>2 slice(40,60)=>3
      //每页显示 20
      this.hotList = this.tracks.slice(0, 10);
    });
    window.addEventListener("scroll", this.onScroll);
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      window.addEventListener("scroll", vm.onScroll);
    });
  },
  beforeRouteLeave(to, from, next) {
    //移除滚动.
    window.removeEventListener("scroll", this.onScroll);
    next();
  },
  filters: {
    formatDate(value, fstr) {
      let d = new Date(value);
      let formatO = {
        "Y+": d.getFullYear(),
        "m+": d.getMonth() + 1,
        "d+": d.getDate(),
        "h+": d.getHours(),
        "M+": d.getMinutes(),
        "S+": d.getSeconds,
      };
      for (let k in formatO) {
        let reg = new RegExp(k);
        let val = formatO[k];
        val = val < 10 ? "0" + val : val;
        fstr = fstr.replace(reg, val);
      }
      return fstr;
    },
  },
};
</script>
<style lang="less" scoped>
.ranking {
  .topNull {
    height: 40px;
  }
  .top {
    width: 100%;
    height: 40vw;
    background: url(../assets/img/hot_music_bg_2x.jpg) no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    margin: 5px 0;
    .timebox {
      color: white;
      font-size: 14px;
      padding-left: 20px;
      .time {
        width: 142px;
        height: 67px;
        margin-bottom: 10px;
        background: url(../assets/img/logo.png) no-repeat;
        background-size: 166px 97px;
        background-position: -24px -30px;
      }
    }
    .right {
      p {
        padding-left: 10px;
        color: white;
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-overflow: -o-ellipsis-lastline;
        display: -webkit-box;
        -webkit-line-clamp: 3; //行数需设置
        -webkit-box-orient: vertical;
      }
    }
  }
}
</style>